import { toast } from "react-toastify";

const loadModuleJsEncrypt = async () => {
  if (typeof window === 'undefined') {
    // @ts-ignore
    global.window = this;
    const jsencrypt = await import('jsencrypt');
    if (!jsencrypt?.default) throw new Error("Something went wrong!");
    return jsencrypt.default;
  }

  const module = await import("jsencrypt");
  if (!module?.JSEncrypt) throw new Error("Something went wrong!");

  return module.JSEncrypt;
}

/**
 * 
 * @param data String
 * @returns encryptedValue
 */
const encrypt = async (data: any) => {
  try {
    const JSEncrypt = await loadModuleJsEncrypt();

    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.publicKey || "");

    let encryptedData: any;

    if (typeof data === 'object' && !Array.isArray(data) && data !== null) {
      encryptedData = {};

      Object.keys(data)?.forEach((key: string) => {
        if (typeof window === 'undefined') {
          const value = typeof data[key] !== 'string' ? JSON.stringify(data[key]) : data[key];
          encryptedData[key] = encrypt.encrypt(Buffer.from(value, 'binary').toString('base64'));
        } else {
          const value = typeof data[key] !== 'string' ? JSON.stringify(data[key]) : data[key];
          encryptedData[key] = encrypt.encrypt(window.btoa(value));
        }
      });
    } else {
      if (typeof window === 'undefined') {
        const value = typeof data !== 'string' ? JSON.stringify(data) : data;
        encryptedData = encrypt.encrypt(Buffer.from(value, 'binary').toString('base64'));
      } else {
        const value = typeof data !== 'string' ? JSON.stringify(data) : data;
        encryptedData = encrypt.encrypt(window.btoa(value));
      }
    }

    return encryptedData;
  } catch (err) {
    console.log(err);
    toast.error(err?.message || 'Something went wrong.');
  }
}

export default encrypt;
