import encrypt from "utils/encrypt";

export interface ICloutUserRegistrationRequest {
  requesterId?: string;
  name?: string;
  email?: string;
  roles?: string[];
  step?: string | number;
  verificationCode?: string;
  password?: string;
  confirmPassword?: string;
}

export const encryptStaffData = async (data: ICloutUserRegistrationRequest) => {
  const toBeEncryptedKeys = ['name', 'email', 'password', 'confirmPassword'];
  const encryptedData: any = { ...data };

  for (const key of Object.keys(encryptedData)) {
    if (toBeEncryptedKeys.includes(key)) {
      encryptedData[key] = await encrypt(encryptedData[key]);
    }
  }

  return encryptedData;
}
